<template>
  <b-modal
    id="modal-group-create-info"
    centered
    no-close-on-backdrop
    no-close-on-esc
    footer-class="flex-nowrap"
  >
    <template #modal-header>
      <h3>{{ $t('modals.infoGroup.title') }}</h3>
    </template>
    <div class="show-url-icon text-center px-3">
      <b-icon icon="exclamation-triangle" variant="info" class="mt-3 mb-2"></b-icon>
      <div class="mt-2 mb-4 mx-4" v-html="$t('modals.infoGroup.text')"></div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="closeInfoModal"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalGroupCreateInfo',
  data() {
    return {
      pageTypeLocaleString: null,
    };
  },
  mounted() {
    this.$bvModal.show('modal-group-create-info');
  },
  methods: {
    closeInfoModal() {
      this.$bvModal.hide('modal-group-create-info');
      this.$emit('close-info-modal');
    },
  },
};
</script>

<style scoped lang="scss">
.show-url-icon svg {
  font-size: 50px;
}
</style>
